@charset "UTF-8";
// SASS関数を上書きする関数。CSS関数を使えるようにするため。
@function min($values...) {
  @return unquote('min(#{$values})');
}
@function max($values...) {
  @return unquote('max(#{$values})');
}
@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  $counter: 1;
  $is-index: 0;
  @if $childname != 0 {
    @return index($getkey, $childname);
  }

  @each $map-key, $map-value in $z-map {
    @if $map-key == $name {
      $is-index: $counter;
    }
    $counter: $counter + 1;
  }

  @return $is-index;
}
// z-index管理。bodyの子要素のみ管理します。
$z-map: (
        index-event: true,
        index-diagnostics_link: true,
        index-hr_link: true,
        cmn-header: true,
        blocker: true,
);
// colors
$color-main: #ff8b44;
$color-orange:#ff7827;

// font-weight
$weight-bold: 700;
$weight-heavy: 900;

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '';
  }
}

// for smartphone media query
@mixin sp {
  @media screen and (max-width:600px) {
    @content;
  }
}

// hover inverted
@mixin hover_inverted($color,$bg-color) {
  transition: .2s;
  &:hover {
    color: $color;
    background-color: lighten($bg-color,5%);
  }
}

// hover text
@mixin hover_text($color) {
  color: $color;
  transition: 0.15s;
  &:hover {
    color: darken($color,15%);
  }
}

// sp page hover button
@mixin hoverbtn($color) {
  transition: .2s;
  &:hover {
    background-color: darken($color, 8%)
  }
}

// icon font
@mixin icon_font {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}